@import './variables';

html {
  scroll-behavior: smooth;
}

body {
  input {
    ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
    ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
    ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
      color: transparent;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $primary !important;
  }

  .mat-snack-bar-container {
    @apply bg-blue-600;

    &.mat-snack-bar-top {
      margin-bottom: unset !important;
    }
    .mat-button-wrapper {
      color: #a1d1f5 !important;
      span {
        display: none !important;
      }
    }
    &.white {
      background: #fff !important;
      color: $primary !important;
      .mat-button-wrapper {
        color: #003b5b !important;
        span {
          display: none !important;
        }
      }
    }
    &.error {
      @apply bg-bey-red text-white;
    }
  }

  .intercom-namespace .intercom-1yuw37k,
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      bottom: 95px !important;
      right: 3px !important;
    }
    .mat-dialog-container {
      position: relative !important;
    }
    .close.mat-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      line-height: 14px;
      min-width: auto;
    }

    // General use styles for headers, body, etc.
    .bey-subtitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: rgba(0, 31, 48, 0.4);
    }
  }

  .mat-dialog-actions {
    flex-wrap: nowrap !important;
  }

  .mat-dialog-container {
    padding: 24px 15px;
    margin: 0 !important;
    @apply rounded-2xl md:rounded-lg bg-white;
  }

  .narrow-mat-dialog-container-padding {
    .mat-dialog-container {
      padding: 15px 5px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px white inset !important;
    background-color: white !important;
    background-clip: content-box !important;
  }

  .custom-dialog-backdrop {
    background: rgba(0, 69, 107, 0.8);
    backdrop-filter: blur(12px);
  }
  .snack-bar-margin-bottom {
    margin-bottom: 75px !important;
  }
  .remove-inner-padding .mat-dialog-container {
    padding: 0 !important;
  }
}

.mat-simple-snack-bar-content {
  color: white;
}

.bey-transfer-info-sidebar-main {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.bey-transfer-info-sidebar-content {
  flex: 1;
  background: #f9f9f9;
  min-width: 0;
}

.bey-transfer-info-sidebar.open {
  margin-left: 0;
  z-index: 30;

  &.over {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 480px;
    z-index: 30;
    box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 1200px) {
    & {
      transform: translateX(0);
    }
  }
}

.bey-transfer-info-sidebar.closed {
  transition: all 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55), z-index 0s;
  margin-left: -30em;
  z-index: 10;
  @media screen and (max-width: 1200px) {
    & {
      position: absolute;
      transform: translateX(100%);
      opacity: 0;
      z-index: -1;
    }
  }
}

.bey-transfer-info-sidebar {
  flex: 0 0 30em;
  transition: all 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55), z-index 700ms cubic-bezier(1, 0, 1, 0.01);
  min-height: 100vh;
  max-width: 480px;
  width: 0;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    & {
      position: fixed;
      z-index: 99;
      width: 50%;
      right: 0;
      top: 0;
      bottom: 0;
    }

    //prevent sticky on child because we already have the parent absolute
    & .sticky {
      position: relative;
    }
  }
}

.bey-transfer-info-sidebar.over {
  transition: all 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
  position: absolute;
  right: -480px;
  top: 0;
  bottom: 0;
  z-index: 30;
}

.grecaptcha-badge {
  visibility: hidden;
}

tr.new-transaction-item {
  & > td {
    &::before {
      transform: translateZ(-1px);
      background-color: #b6dcf942;
    }
  }
}
