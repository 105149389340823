@import './app/styles/base';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  // Remove highlight when tapping on links
  a,
  .hide-tap {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    font-family: 'proxima-nova', sans-serif !important;
    font-display: swap;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.search-width-fix {
  [type='search'] {
    -webkit-appearance: unset;
    outline-offset: unset;
  }
}

body.rejection {
  #content {
    padding: 0;
    height: 100%;

    .rejection {
      height: 100%;

      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        & {
          height: auto;
          padding: 5rem;
        }
      }

      bey-button span {
        padding: 8px 0;
      }

      .comment-wrap {
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
        border-radius: 8px;
      }
    }
  }

  aside,
  footer {
    display: none;
  }
}
